import React from 'react';
import {BsLinkedin, BsGithub } from 'react-icons/bs';
import {FaInstagram } from 'react-icons/fa';


const SocialMedia = () => {
  return (
    <div className='app__social'>
      <a href="https://www.linkedin.com/in/ahmedalla/">
      <div>
        <BsLinkedin />
      </div>
      </a>
      <a href="https://github.com/ahmedhanora11">
      <div>
        <BsGithub />
      </div>  
      </a>  
        
      <a href="https://www.instagram.com/ahmedhanora11/">
      <div>
        <FaInstagram />
      </div>
      </a>
        
    </div>
  )
}

export default SocialMedia